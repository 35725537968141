import React from 'react';

import { Button, ButtonType } from '@eika/button';
import { CheckmarkAnimation } from '@eika/checkmark-animation';

interface CookieFormSubmitProps {
    actions: { type: 'primary' | 'secondary' | 'tertiary'; onClick?: () => void; text: string }[];
    showSuccesss: boolean;
    successText: string;
}

const getButtonType = (type: 'primary' | 'secondary' | 'tertiary'): ButtonType => {
    switch (type) {
        case 'tertiary':
            return 'link';
        case 'secondary':
            return 'negative';
        case 'primary':
        default:
            return 'positive';
    }
};

const CookieFormSubmit = ({ actions, showSuccesss, successText }: CookieFormSubmitProps): JSX.Element => (
    <div className="cookie-consent-form__submit">
        {showSuccesss ? (
            <div className="success-card" role="alert" aria-live="assertive">
                <CheckmarkAnimation mini key={1} />
                <div className="card-body">{successText}</div>
            </div>
        ) : (
            <div className="cookie-consent-form__button-wrapper">
                {actions.map(({ type, onClick, text }) =>
                    onClick ? (
                        <Button
                            key={type}
                            buttonType={getButtonType(type)}
                            type="button"
                            data-testid={`${type}-cookie-action`}
                            size={type !== 'tertiary' ? 'large' : undefined}
                            className={`cookie-consent-form__button--${type}`}
                            onClick={onClick}
                        >
                            {type === 'tertiary' ? <span className="sgw-link__text">{text}</span> : text}
                        </Button>
                    ) : null,
                )}
            </div>
        )}
    </div>
);

export default CookieFormSubmit;
