import { useConfig as hook, BaseConfig } from '@eika-infrastruktur/config-provider';

import nbTranslations from './translations/nbTranslations-config';
import nnTranslations from './translations/nnTranslations-config';
import TranslationsConfigType from './translations/translationType';

export const APP_NAMESPACE = 'marked.cookie-consent';

export const COOKIE_CONSENT_SET_KEY = 'eika_consent_set';

export interface Cookie {
    isDisabled?: boolean;
    header: string;
    description: string;
    cookieName: string;
    default?: boolean;
}

export interface Config extends BaseConfig {
    siteLang: 'nb' | 'nn';
    translations: {
        nb: TranslationsConfigType;
        nn: TranslationsConfigType;
    };
}

export const defaultConfig: Config = {
    siteLang: 'nb' as const,
    translations: {
        nb: nbTranslations,
        nn: nnTranslations,
    },
};

function createHook<T>(): () => T {
    return () => hook<T>();
}
export const useConfig = createHook<typeof defaultConfig>();
