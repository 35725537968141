import React from 'react';

import { loadContainers } from '@eika-infrastruktur/render';

import { defaultConfig, APP_NAMESPACE } from './default-config';
import Form from './widgets/cookie-form';
import Modal from './widgets/popup';

require('./styles/main.scss');

const renderApp = (MyApp: React.ComponentType, namespace: string) => {
    loadContainers(
        `${APP_NAMESPACE}.${namespace}`,
        () => (
            <div className="no-eika-cookieconsent">
                <MyApp />
            </div>
        ),
        defaultConfig,
    );
};

renderApp(Modal, 'popup');
renderApp(Form, 'form');
