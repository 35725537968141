import TranslationsConfigType from './translationType';

const nbTranslations: TranslationsConfigType = {
    modal: {
        header: 'Bruk av informasjonskapsler (Cookies)',
        subHeader: 'Vi bruker informasjonskapsler på nettsidene våre for at:',
        description: [
            'Nettsidene skal fungere teknisk.',
            'Nettsidene kan tilpasses dine valg og innstillinger.',
            'Vi skal kunne samle statistikk om hvordan nettsidene brukes, og dermed ha grunnlag for å kunne forbedre brukeropplevelsen.',
            'Vi skal kunne analysere effekt av markedsføring, og dermed ha grunnlag for å optimalisere markedsføring og kommunikasjon.',
        ],
        acceptAll: 'Godta alle',
        edit: 'Les mer og velg informasjonskapsler',
        onlyNecessary: 'Kun nødvendige',
    },
    form: {
        header: 'Informasjon om valg av informasjonskapsler',
        saveChanges: 'Lagre endringer',
        cancel: 'Avbryt',
    },
    success: {
        header: 'Lagret',
        firstTimeSaveDescription: 'Valgene dine ble lagret',
        description: 'Endringene dine ble lagret',
        timeout: 1000,
    },
    cookies: {
        technical: {
            header: 'Teknisk nødvendige',
            description: 'Er nødvendige for at nettsidene våre skal fungere. Du kan derfor ikke velge disse bort.',
            cookieName: 'eika_consent_set',
            isDisabled: true,
        },
        marketing: {
            header: 'Markedsføring',
            description: 'Hjelper oss å effektivisere vår markedsføring og kommunikasjon.',
            cookieName: 'eika_consent_marketing',
        },
    },
};

export default nbTranslations;
