window.dataLayer = window.dataLayer || [];
const { dataLayer } = window;

function dispatchNativeEvent(cookieName: string) {
    const event = document.createEvent('Event');
    event.initEvent(cookieName, true, true);
    window.dispatchEvent(event);
}

function dispatchGTMEvent(cookieName: string, value: boolean) {
    dataLayer.push({
        event: 'eika_cookie_consent_changed',
        [cookieName]: value,
    });
}

export default function notifyChange(cookieName: string, value: boolean): void {
    dispatchNativeEvent(cookieName);
    dispatchGTMEvent(cookieName, value);
}
