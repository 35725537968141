import classNames from 'classnames';
import React, { FC } from 'react';

import '@eika/checkbox-style';
import { Icon, IconTypes } from '@eika/icon';

import { Cookie } from '../../default-config';

import './CookieSelect.scss';

interface CookieProps {
    cookie: Cookie;
    onChange: (cookieName: string) => void;
    checked: boolean;
}

const CookieSelect: FC<CookieProps> = ({ cookie, onChange, checked }) => {
    const isForced = cookie.isDisabled;
    const actuallyChecked = isForced || Boolean(checked);

    return (
        <li
            className={classNames('sgw-checkmark-list__checkmark-item cookie-item', {
                'editable sgw-checkbox sgw-checkbox__with_focus': !isForced,
            })}
        >
            {isForced ? (
                <>
                    <div
                        className={classNames('sgw-checkmark-list__icon', {
                            'sgw-checkmark-list__icon--unchecked': !checked,
                        })}
                        data-testid="cookie-select"
                    >
                        <Icon type={checked ? IconTypes.CHECKMARK_TIL_SIRKEL_24 : IconTypes.KRYSS_24} />
                    </div>
                    <p className="semibold">{cookie.header}</p>
                </>
            ) : (
                <>
                    <input
                        data-testid="cookie-select"
                        type="checkbox"
                        value={cookie.cookieName}
                        checked={actuallyChecked}
                        onChange={() => onChange(cookie.cookieName)}
                        id={cookie.cookieName}
                    />
                    <label className="semibold" htmlFor={cookie.cookieName}>
                        {cookie.header}
                    </label>
                </>
            )}

            <p className="cookie-select__description">{cookie.description}</p>
        </li>
    );
};
export default CookieSelect;
