import { Cookie } from '../default-config';
import { CookieList, setCookie } from '../services/cookie-service';
import notifyChange from '../services/notification-service';

export interface CookieType {
    [key: string]: Cookie;
}

export const getCookieNames = (cookies: CookieType): string[] =>
    Object.values(cookies).map((cookie) => cookie.cookieName);

export const setCookiesAndNotifyChange = (cookies: CookieList): void => {
    Object.keys(cookies).forEach((cookieName) => {
        const value = cookies[cookieName];
        setCookie(cookieName, value);
        notifyChange(cookieName, value);
    });
};

export const acceptAll = (cookies: CookieType): void => {
    const allConsents: CookieList = Object.values(cookies)
        .map((cookie) => cookie.cookieName)
        .reduce(
            (list, name) => ({
                ...list,
                [name]: true,
            }),
            {},
        );
    setCookiesAndNotifyChange(allConsents);
};
