import React, { FC } from 'react';

import CookieForm from '../../components/cookie-form/CookieForm';
import useTranslation from '../../hooks/useTranslation';

const Form: FC = () => {
    const { modal, cookies } = useTranslation();

    return (
        <div className="card standard-card left-adjusted">
            <div className="card-content">
                <div className="card-wrap">
                    <h1>{modal.header}</h1>
                    <div className="card-ingress">
                        <h3 className="unstyled">{modal.subHeader}</h3>
                        <ul>
                            {modal.description.map((desc) => (
                                <li key={desc}>{desc}</li>
                            ))}
                        </ul>
                    </div>
                    <CookieForm enableEdit cookies={cookies} />
                </div>
            </div>
        </div>
    );
};

export default Form;
