import React, { FC } from 'react';

import CookieForm from '../../components/cookie-form/CookieForm';
import { CookieModal } from '../../components/cookie-modal';
import useCookiePopup from '../../hooks/useCookiePopup';
import useTranslation from '../../hooks/useTranslation';

import './popup.scss';

const Popup: FC = () => {
    const { success, modal, cookies } = useTranslation();

    const { closeModal, edit, setEdit, open, setOpen } = useCookiePopup(cookies, success.timeout);

    return (
        <CookieModal onClose={closeModal} open={open} title={modal.header}>
            <div className="card-ingress">
                <h3 className="unstyled">{modal.subHeader}</h3>
                <ul>
                    {modal.description.map((desc) => (
                        <li key={desc}>{desc}</li>
                    ))}
                </ul>
            </div>
            <CookieForm
                enableEdit={edit}
                cookies={cookies}
                onCloseModal={() => setOpen(false)}
                onEdit={() => setEdit(true)}
                onCancel={() => setEdit(false)}
            />
        </CookieModal>
    );
};

export default Popup;
