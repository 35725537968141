import React from 'react';

import { Modal, ModalDialogProps } from '@eika/modal';

type CookieModalProps = Omit<ModalDialogProps, 'appNamespace'>;

const CookieModal = ({ children, ...rest }: CookieModalProps): JSX.Element => {
    return (
        <Modal
            id="cookie-consent-modal"
            appNamespace="marked-cookie-consent__modal"
            hideCloseButton
            size="wide"
            contentShouldStretch
            desktopMinHeight="small"
            {...rest}
        >
            {children}
        </Modal>
    );
};

export default CookieModal;
