import { useState, useEffect, useCallback } from 'react';

import { COOKIE_CONSENT_SET_KEY } from '../default-config';
import { CookieList, cookieService } from '../services/cookie-service';
import { acceptAll, CookieType, getCookieNames, setCookiesAndNotifyChange } from '../utils/cookie-utils';

interface UseCookie {
    consents: CookieList;
    saved: boolean;
    onChange: (cookieName: string) => void;
    onAcceptAll: () => void;
    onSave: () => void;
    setCookiesToDefault: () => void;
}

const useCookie = (cookies: CookieType, successTimeout: number, onAccept?: () => void): UseCookie => {
    const [consents, setConsents] = useState({} as CookieList);
    const [saved, setSaved] = useState<boolean>(false);

    const readCookies = useCallback(() => {
        const cookieNames = getCookieNames(cookies);
        const cookieValues = cookieService(cookieNames);
        setConsents({
            ...cookieValues,
            [COOKIE_CONSENT_SET_KEY]: true,
        });
    }, [cookies]);

    useEffect(() => {
        const cookieNames = getCookieNames(cookies);
        cookieNames.forEach((cookieName) => {
            window.addEventListener(cookieName, readCookies, false);
        });
        return () => {
            cookieNames.forEach((cookieName) => window.removeEventListener(cookieName, readCookies));
        };
    }, [cookies, readCookies]);

    const onSaved = () => {
        setSaved(true);
        setTimeout(() => {
            setSaved(false);
            onAccept?.();
        }, successTimeout);
    };

    useEffect(() => {
        readCookies();
    }, [cookies, readCookies]);

    const onChange = (cookieName: string) => {
        setConsents({
            ...consents,
            [cookieName]: !consents[cookieName],
        });
    };

    const onSave = () => {
        setCookiesAndNotifyChange(consents);
        onSaved();
    };

    const onAcceptAll = () => {
        acceptAll(cookies);
        onSaved();
    };

    const setCookiesToDefault = () => {
        const cookieNames = getCookieNames(cookies);
        const cookieValues = cookieService(cookieNames);
        setCookiesAndNotifyChange({ ...cookieValues, [COOKIE_CONSENT_SET_KEY]: true });
    };

    return { consents, saved, onChange, onAcceptAll, onSave, setCookiesToDefault };
};

export default useCookie;
