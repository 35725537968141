const EIKA_CONSENT_COOKIE_PREFIX = 'eika_consent_';
const maxAge10Years = 60 * 60 * 24 * 365 * 10;
const today = new Date();

function isLocalTest() {
    return window.location.protocol === 'http:';
}

function splitCookie(cookie: string): string[] {
    return cookie.split('=');
}

export interface CookieList {
    [cookieName: string]: boolean;
}

function getCookiesParsed() {
    return document.cookie
        .split(';')
        .map((c) => c.trim())
        .map(splitCookie);
}

export function getCookieValue(cookieName: string): boolean {
    const cookie = getCookiesParsed().find(([cn]) => cn === cookieName);
    if (cookie) {
        return cookie[1] === 'true';
    }
    return false;
}

function getCookieSecurity() {
    if (isLocalTest()) {
        return '';
    }
    return ';secure; samesite=lax';
}

const getExpires = () => new Date(today.getTime() + maxAge10Years).toUTCString();

export function setCookie(cookieName: string, value: boolean): void {
    document.cookie = `${cookieName}=${value}; path=/; max-age=${maxAge10Years}; expires=${getExpires()}; ${getCookieSecurity()}`;
}

export function refreshCookies(): void {
    getCookiesParsed()
        .filter(([name]) => name.startsWith(EIKA_CONSENT_COOKIE_PREFIX))
        .forEach(([cookieName, value]) => setCookie(cookieName, value === 'true'));
}

export function cookieService(cookieNames: string[]): CookieList {
    const eikaCookies: CookieList = getCookiesParsed()
        .filter(([name]) => name.startsWith(EIKA_CONSENT_COOKIE_PREFIX))
        .reduce(
            (list, [cookieName, value]) => ({
                ...list,
                [cookieName]: value === 'true',
            }),
            {},
        );

    return cookieNames.reduce((list, cookieName) => {
        const value = Boolean(eikaCookies[cookieName]);
        return {
            ...list,
            [cookieName]: value,
        };
    }, {});
}
