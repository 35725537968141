import { useEffect, useState } from 'react';

import { COOKIE_CONSENT_SET_KEY } from '../default-config';
import { getCookieValue, refreshCookies } from '../services/cookie-service';
import { CookieType } from '../utils/cookie-utils';
import useCookie from './useCookie';

interface CookiePopup {
    closeModal: () => void;
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCookiePopup = (cookies: CookieType, successTimeout: number): CookiePopup => {
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const { setCookiesToDefault } = useCookie(cookies, successTimeout, () => setOpen(false));

    useEffect(() => {
        if (!getCookieValue(COOKIE_CONSENT_SET_KEY)) {
            setOpen(true);
        } else {
            // Safari seems to cap the expiry to 7 days
            refreshCookies();
        }
    }, []);

    const closeModal = () => {
        setCookiesToDefault();
        setOpen(false);
    };

    return { closeModal, edit, setEdit, open, setOpen };
};

export default useCookiePopup;
