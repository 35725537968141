import TranslationsConfigType from './translationType';

const nnTranslations: TranslationsConfigType = {
    modal: {
        header: 'Bruk av informasjonskapslar (Cookies)',
        subHeader: 'Vi brukar informasjonskapslar på nettsidene våre for at:',
        description: [
            'Nettsidene skal fungere teknisk.',
            'Nettsidene kan tilpassast dine val og innstillingar.',
            'Vi skal kunne samle statistikk på korleis nettsidene brukast, og dermed ha grunnlag for å kunne forbetre brukaropplevinga betre.',
            'Vi skal kunne analysera effekt av marknadsføring, og dermed ha grunnlag for å optimalisera marknadsføring og kommunikasjon.',
        ],
        acceptAll: 'Godta alle',
        edit: 'Les mer og vel informasjonskapslar',
        onlyNecessary: 'Kun nødvendige',
    },
    form: {
        header: 'Informasjon om val av informasjonskapslar',
        saveChanges: 'Lagre endringane',
        cancel: 'Avbryt',
    },
    success: {
        header: 'Lagra',
        firstTimeSaveDescription: 'Valane dine vart lagra',
        description: 'Endringene dine vart lagra',
        timeout: 1000,
    },
    cookies: {
        technical: {
            header: 'Teknisk nødvendige',
            description: 'Er nødvendige for at nettsidene våre skal fungere. Du kan derfor ikkje velja desse bort.',
            cookieName: 'eika_consent_set',
            isDisabled: true,
        },
        marketing: {
            header: 'Marknadsføring',
            description: 'Hjelper oss å effektivisera marknadsføringa og kommunikasjonen vår.',
            cookieName: 'eika_consent_marketing',
        },
    },
};

export default nnTranslations;
